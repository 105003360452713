import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
interface DateMonthPickerProps {
  setFinal: React.Dispatch<React.SetStateAction<string>>;
  setMonthRange: React.Dispatch<React.SetStateAction<{ from: Date, to: Date }>>;
  callBack: (data: any, subNode: any, node: any) => void;
  subNode: any;
  node: any;
  maxDate: Date;
  monthRange: { from: Date, to: Date }

}
const monthInWords = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

const formatDate = (from: Date, to: Date) =>
  `${monthInWords[from.getMonth()].toString()},${from
    .getFullYear()
    .toString()}-${monthInWords[to.getMonth()].toString()},${to
      .getFullYear()
      .toString()}`;

const MonthRangePicker: React.FC<DateMonthPickerProps> = ({
  setFinal,
  subNode,
  node,
  callBack,
  monthRange,
  setMonthRange,
  maxDate,
}) => {
  const today = new Date();


  //   React.useEffect(() => {
  //     const today = new Date();
  //     const Range = formatDate(today, today);
  //     callBack(Range, subNode, node);


  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);
  // // console.log("aro",node,subNode);



  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={["month", "year"]}
          label="From"
          minDate={today}
          maxDate={maxDate}
          value={monthRange.from}
          onChange={(newValue: any) => {
            setMonthRange(d => ({ ...d, from: newValue, to: newValue }))

            const range = formatDate(newValue, newValue);
            setFinal(range);
            callBack(range, subNode, node);

          }}
          renderInput={(params) => (
            <TextField sx={{ m: 2 }} {...params} helperText={null} />
          )}
        />
        <DatePicker
          views={["month", "year"]}
          label="To"
          minDate={monthRange.from}
          maxDate={maxDate}
          value={monthRange.to}
          onChange={(newValue: any) => {
            setMonthRange(d => ({ ...d, to: newValue }))

            const range = formatDate(monthRange.from, newValue);


            setFinal(range);
            callBack(range, subNode, node);

          }}
          renderInput={(params) => (
            <TextField sx={{ m: 2 }} {...params} helperText={null} />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};
export default MonthRangePicker;


